import axios from 'axios';
import MoveTo from 'moveto';
import svg4everybody from 'svg4everybody';
import ScrollMagic from 'scrollmagic';
import page from 'page';
import UAParser from 'ua-parser-js';
import { WebpMachine } from 'webp-hero';
import './modernizr';

const screenWidth = window.innerWidth;

page('/*', (ctx, next) => {
  console.log('Front JS all.');

  // Direct Library Call
  svg4everybody();

  // Smooth Scrolling
  const smooth = () => {
    const headerHeight = (screenWidth > 768) ? 140 : 70;
    const moveTo = new MoveTo({
      tolerance: headerHeight
    });
    const scrollTrigger = document.querySelector('.scroller a');
    moveTo.registerTrigger(scrollTrigger);
  };
  smooth();

  // WEBP for Safari
  const ua = new UAParser();
  const browserName = ua.getBrowser().name;
  const osName = ua.getOS().name;
  if (browserName === 'Safari' || browserName === 'Mobile Safari' || osName === 'iOS') {
    console.log('is safari / ios');
    const webpMachine = new WebpMachine();
    webpMachine.polyfillDocument();
  }

  if (new UAParser().getBrowser().name === 'IE') {
    document.body.style.overflow = 'hidden';
    const ieElement = document.createElement('div');
    ieElement.classList.add('ie');
    ieElement.innerHTML = `<div class="ie_text">
      <p class="ie_caution">お使いのブラウザは非推奨です</p>
      <p>推奨ブラウザは下記リンクからダウンロードできます。</p>
      <ul>
        <li>・最新版のGoogle Chrome<a href="https://www.google.com/chrome/" class="recommend_browser">https://www.google.com/chrome/</a></li>
        <li>・最新版のMozilla Firefox<a href="https://www.mozilla.org/ja/firefox/new/" class="recommend_browser">https://www.mozilla.org/ja/firefox/new/</a></li>
      </ul>
    </div>`;
    document.body.appendChild(ieElement);
  }

  next();
});

const infoLangChange = () => {
  const langListWrapper = document.querySelector('.langlist_wrapper');
  const langSwitchElem = document.querySelector('.langswitch_button');
  if (langSwitchElem) {
    langSwitchElem.addEventListener('click', (e) => {
      e.stopPropagation();
      langListWrapper.classList.toggle('is-open');
    });
  }

  const radioGroup = document.querySelector('.langlist');
  radioGroup.addEventListener('change', () => {
    const radio = document.querySelector('[name="lang"]:checked');
    // console.log(location.pathname + "?locale=" + radio.value);
    let urlParam = "?locale=" + radio.value;
    let categoryParam = getUrlParam('category');
    if (categoryParam) {
      urlParam = urlParam + '&category=' + categoryParam;
    }
    let pageParam = getUrlParam('page');
    if (pageParam) {
      urlParam = urlParam + '&page=' + pageParam;
    }

    location.href = location.pathname + urlParam;
  });

  document.body.addEventListener('click', () => {
    // 言語選択リストが開いている場合
    if (langListWrapper.classList.contains('is-open')) {
      langListWrapper.classList.remove('is-open');
    }
  })
};

infoLangChange();

function getUrlParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

page('/:serviceType', (ctx, next) => {
  //console.log('Info Blog Index');
  const pcTagElem = document.querySelector('div.pc p.news_tag');
  const categoryList = document.querySelector('div.pc ul.news_tag_list');

  if (!pcTagElem) {
    console.log('Tag not detected.');
    return;
  }

  const redirectCategory = (category) => {
    let urlParams = [];
    let localeParam = getUrlParam('locale');
    if (localeParam) {
      urlParams[urlParams.length] = 'locale=' + localeParam;
    }
    if (category) {
      urlParams[urlParams.length] = 'category=' + category;
    }

    location.href = location.pathname + '?' + urlParams.join('&');
  };

  const pcListToggle = e => {
    console.log('PC Tag Toggled.');
    e.stopPropagation();
    if (!categoryList.getAttribute('style')) {
      categoryList.style.display = 'flex';
      return;
    }
    categoryList.removeAttribute('style');
  }

  const closeCategoryList = () => {
    if (categoryList.getAttribute('style')) {
      categoryList.removeAttribute('style');
    }
  };

  pcTagElem.addEventListener('click', pcListToggle);
  document.body.addEventListener('click', () => {
    console.log('Body Clicked (To Close Tag Selector)');
    closeCategoryList();
  });

  const subTagClicked = e => {
    e.stopPropagation();
    const clickedElem = e.currentTarget;
    if (clickedElem.classList.contains('is-category-active')) {
      // Close Window and Do nothing.
      closeCategoryList();
      return;
    }
    categoryList.querySelector('li.news_tag_item.is-category-active').classList.remove('is-category-active');
    clickedElem.classList.add('is-category-active');
    pcTagElem.innerText = clickedElem.innerText;

    redirectCategory(clickedElem.getAttribute('data-category'));
    return;
  };
  categoryList.querySelectorAll('li.news_tag_item').forEach(tagElem => {
    tagElem.addEventListener('click', subTagClicked);
  });

  // Sub Tag Selector (SP)
  const spSubTagChanged = e => {
    const selectElem = e.currentTarget;
    document.querySelector('div.pc li.news_tag_item.is-category-active').classList.remove('is-category-active');
    document.querySelector('div.pc li[data-category="' + selectElem.value + '"].news_tag_item').classList.add('is-category-active');
    document.querySelector('div.pc p.news_tag').innerText = selectElem.options[selectElem.selectedIndex].text;

    redirectCategory(selectElem.value);
    return;
  };
  document.querySelector('div.sp select').addEventListener('change', spSubTagChanged);
});

// Special Treatment for Preview
page('/admin/blog/preview', (ctx, next) => {
  console.log('Preview Mode!');
  document.querySelectorAll('a').forEach(elem => {
    elem.removeAttribute('href');
  });
});

page.start({
  click: false,
  popstate: false
});
